@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family:
    ui-sans-serif,
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    "Noto Sans",
    "Segoe UI",
    Roboto,
    "Helvetica Neue",
    Arial,
    "Noto Sans JP",
    sans-serif,
    "Apple Color Emoji",
    "Segoe UI Emoji",
    "Segoe UI Symbol",
    "Noto Color Emoji";
}

.required:after {
  content: " *";
  color: red;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 4px;
}

.scrollbar::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #c0c0c0;
  border-radius: 100vh;
  border: 2px solid #c0c0c0;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background: #c0c0c0;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tabNav::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tabNav {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.slick-slide > div {
  margin: 8px;
}
.slick-list {
  margin: 0 -8px;
}

.slick-prev:before,
.slick-next:before {
  color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* .image-gallery {
  width: 70%;
  height: auto;
  margin: auto;
}

.image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 80vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

.fullscreen .image-gallery-slide img {
  max-height: 100vh;
} */

.chart-trading-view {
  position: relative;
  box-sizing: content-box;
  font-family: -apple-system, BlinkMacSystemFont, "Trebuchet MS", Roboto,
    Ubuntu, sans-serif;
  margin: 0px auto !important;
  padding: 0px !important;
  width: 100%;
  height: 100%;
}

.iframe-trading-view {
  width: 100%;
  height: 100%;
  margin: 0px !important;
  padding: 0px !important;
}

.cy_item_title {
  font-weight: bold;
  padding-top: 24px;
  padding-bottom: 10px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.cy_item_title b {
  width: 2em;
  height: 2em;
  background: #f7f7f7;
  color: #444;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.cy_item_title_text {
  width: calc(100% - 2em);
}
.cy_li a {
  text-decoration: underline;
}
.cy_li p {
  font-size: 16px;
  padding-left: 20px;
  position: relative;
}
.cy_li p::before {
  content: "";
  width: 5px;
  height: 5px;
  background: #333;
  border-radius: 50%;
  position: absolute;
  top: 12px;
  left: 5px;
}

.thumbs-swiper .swiper-slide {
  opacity: 0.4;
}

.thumbs-swiper .swiper-slide-thumb-active {
  opacity: 1;
}

.image-gallery-image {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
}

.image-gallery-thumbnail {
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 16/9;
}
